import {
  Avatar,
  Col,
  Collapse,
  Divider,
  Form,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Steps,
  Typography,
  Upload,
} from "antd";
import { ReactNode, useState } from "react";
import { ITask } from "redux/components/tasks";
import {
  NO_IMAGE,
  NO_IMAGE_AVATAR,
} from "components/shared/assets-table/AssetsTable.constants";

import AlectifyText from "static/texts.json";
import { useNavigate } from "react-router-dom";
import { isEmpty, truncate } from "lodash";
import TextToLink from "components/shared/text-to-link";
import { IProcedure } from "services/procedures/procedures.interface";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import StatusCard from "components/shared/status-card/StatusCard";
import { PME_ACTION_REQUIRED_ENUM, TaskPriorityEnum } from "enums";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { StepProps } from "antd/lib";
import ViewImage from "components/shared/view-image/ViewImage";
import { displayDateTime, enumToTile } from "utils/helpers";
import { updatePrevMaintenances } from "services/pm-external/pm-external.service";
import { MESSAGES } from "constants/messages";
import DayJs from "dayjs";
import { PM_DETAIL_PRIORITY_COMPONENTS } from "components/shared/tasks-table/effects/useSimpleColumns";
import { PM_TYPES } from "redux/components/pm-external";
import AlectifyDescription from "components/shared/detailed-view/AlectifyDescriptionView";
import WorkOrderSpareParts from "components/shared/work-order-spare-parts";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import ProcedureDetail from "components/shared/procedure-detail";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import AssetDetail from "components/shared/asset-detail";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import AlectifyButton from "components/shared/button";
import DrawWorkOrderSparePart from "components/shared/work-order-spare-parts/DrawWorkOrderSparePart";
import "./style.scss";
import { ISparePartDraw } from "redux/components/spare-parts";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { fetchWorkOrderSparePartsHisotry } from "services/spare-parts/spare-parts.service";

interface IWorkOrderStatusProps {
  task: ITask | null;
  actionButton: ReactNode;
  loader: boolean;
  prepareRoutes: (type: string) => string;
  submittedInformationModal: () => void;
  getPmExternalDetails: (value: any) => void;
  procedure: IProcedure;
  taskId?: string;
  subProjectId?: string;
  referenceId: string;
  isDisabled: boolean;
}

const WorkOrderStatus: React.FC<IWorkOrderStatusProps> = (
  props: IWorkOrderStatusProps,
) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [FormInstance] = Form.useForm();
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

  const openImagePreviewModal = (url: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
        });
      },
      name: MODAL_NAMES.TASK_TABLE_IMAGE_MODAL,
      title: "Preview",
      footer: null,
      children: <ViewImage imageUrl={url} />,
    });
  };

  const getStepsItems = (): StepProps[] => {
    const items: StepProps[] = [
      {
        title: "Created Work Order",
        description: (
          <Space direction="vertical" size={1}>
            <span className="status-description-step-date-text">
              {`${displayDateTime(props.task?.createdAt, true, false)}`}
            </span>
            {props.task?.createdAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )}
          </Space>
        ),
        status: (!props.task?.createdAt && "error") || "finish",
        icon: (
          <Avatar
            size={"large"}
            src={props.task?.createdBy?.image_url || NO_IMAGE_AVATAR}
          />
        ),
      },
      {
        title: (
          <Space className="review-text">
            {props.task?.reviewedAt ? (
              <TextToLink
                text={<span>{AlectifyText.SUBMITTED_REVIEW}</span>}
                onClick={() => props.submittedInformationModal()}
              />
            ) : (
              <span>{AlectifyText.SUBMITTED_REVIEW}</span>
            )}
          </Space>
        ),
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completionAt &&
              `${
                (props.task?.reviewedAt &&
                  displayDateTime(
                    (props.task?.reviewedAt as any) || "-",
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completionAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completionAt && "finish") || "error",
        icon: props.task?.reviewedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.reviewedBy?.image_url} />
        ),
      },
    ];
    if (!props.task?.skippedAt) {
      items.push({
        title: AlectifyText.REVIEW_COMPLETED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.completedAt &&
              `${
                (props.task?.completedAt &&
                  displayDateTime(
                    props.task?.completedAt as any,
                    true,
                    false,
                  )) ||
                "-"
              }`}
            {(props.task?.completedAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.completedAt && "finish") || "error",
        icon: props.task?.completedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.completedBy?.image_url} />
        ),
      });
    }
    if (props.task?.skippedAt) {
      items.push({
        title: AlectifyText.SKIPPED,
        description: (
          <Space direction="vertical" size={1}>
            {props.task?.skippedAt &&
              `${
                (props.task?.skippedAt &&
                  displayDateTime(props.task?.skippedAt as any, true, false)) ||
                "-"
              }`}
            {(props.task?.skippedAt && (
              <span className="completed-text">{AlectifyText.COMPLETED}</span>
            )) || <span>Not Done</span>}
          </Space>
        ),
        status: (props.task?.skippedAt && "finish") || "error",
        icon: props.task?.skippedBy?.image_url && (
          <Avatar size={"large"} src={props.task?.skippedBy?.image_url} />
        ),
      });
    }
    return items;
  };

  const handleFileUpload = async (file: any) => {
    const { task } = props;
    setIsLoadingImage(true);

    try {
      const formData = new FormData();

      if (task && !isEmpty(task)) {
        const taskFields = [
          { key: "taskCategory", value: task.taskCategory },
          { key: "pm_tracking", value: "asset" },
          { key: "assetLevel", value: task.asset ? "asset" : "area" },
          { key: "workTitle", value: task.workTitle },
          { key: "priority", value: task.priority || "-" },
          { key: "detail", value: task.detail || "-" },
          { key: "projectId", value: task.project?.id },
          { key: "subProjectId", value: task.subProject?.id },
          { key: "dayType", value: task.dayType },
          { key: "day", value: task.day },
          { key: "date", value: task.date },
          { key: "week", value: task.week },
          { key: "frequencyType", value: task.frequencyType?.toUpperCase() },
          { key: "frequency", value: task.frequency },
          { key: "isRecurring", value: task.isRecurring.toString() },
          {
            key: "changeAllFuturePM",
            value: task.isRecurring ? "true" : "false",
          },
          { key: "assetId", value: task.asset?.id },
          { key: "areaId", value: task.area?.id },
          { key: "procedureLibraryId", value: task.procedureLibraryId as any },
          { key: "image", value: file },
          {
            key: "dueDate",
            value:
              task.dueDate && DayJs(task.dueDate).hour(6).minute(0).format(),
          },
        ];

        taskFields.forEach(({ key, value }) => {
          if (value) formData.append(key, value);
        });
      }

      const response = await updatePrevMaintenances(task?.id || "-", formData);
      if (response.status) {
        message.success(MESSAGES.GENERAL_MESSAGES.IMAGE_UPLOADED);
      }
    } catch (error: any) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error(error);
    } finally {
      setIsLoadingImage(false);
      props.getPmExternalDetails?.(task?.id);
    }
  };

  const formatDateFrequency = (
    date: string | number,
    frequency: string | number,
  ) => {
    if (date === 1 && frequency === 1) {
      return "This work order repeats on the first day of every month.";
    }
    if (frequency === 1) {
      return `This work order repeats on the ${date} day of every month.`;
    }
    return `This work order repeats on the ${date} day of every ${frequency} months.`;
  };

  const formatDayFrequency = (
    frequency: string | number,
    day: string | number,
    week: string | number,
  ) => {
    if (frequency === 1) {
      return `This work order repeats every month on ${week} week  of ${day}.`;
    }
    return `This work order repeats every ${frequency} months on ${week} week of the ${day}.`;
  };

  const renderWorkOrderRepeats = () => {
    const { frequencyType, dayType, date, frequency, day, week } =
      props.task ?? {};

    switch (frequencyType) {
      case "WEEKLY":
        return `This work order repeats weekly every ${day}.`;

      case "MONTHLY":
        if (dayType === "date") {
          return formatDateFrequency(date, frequency);
        }
        if (dayType === "day") {
          return formatDayFrequency(frequency, day, week);
        }
        break;

      case "DAILY":
        return "This work order repeats everyday.";

      default:
        return null;
    }
  };

  const openAssetDetail = (record: ITask | null) => {
    if (!record?.isGeneric) {
      DrawerServiceInstance.open(AlectifyDrawer, {
        width: record?.asset?.name ? 420 : 900,
        title: truncate(record?.asset?.name || record?.area?.name, {
          length: 30,
          omission: "...",
        }),
        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
        closable: true,
        closeIcon: <CloseOutlined />,
        onClose: () =>
          DrawerServiceInstance.close(AlectifyDrawer, {
            name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
          }),
        children: !isEmpty(record?.asset) ? (
          <AssetDetail
            taskRecord={record}
            subProjectId={record?.subProject?.id}
            assetId={record?.asset?.id}
            navigate={navigate}
            isDetailButton
            isUploadbutton
          />
        ) : (
          <PackageDetailAssets
            taskRecord={record}
            subProjectId={record?.subProject?.id || ""}
            assetPackageId={record?.area?.id || ""}
            navigate={navigate}
            isDetailsbutton
            isUploadbutton
          />
        ),
      });
    }
  };

  const [history, setHistory] = useState<{
    data: ISparePartDraw[];
    total: number;
    fetching: boolean;
  }>({ fetching: false, data: [], total: 0 });

  const getHistory = async (options?: IPagination) => {
    try {
      if (props.task) {
        setHistory({ ...history, fetching: true });
        const params = {
          page: options?.page || PAGINATION.DEFAULT_START_PAGE,
          limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        };
        const response = await fetchWorkOrderSparePartsHisotry(
          props?.task?.id,
          params,
        );
        setHistory({
          fetching: false,
          data: response.data,
          total: response.meta?.totalItems || 0,
        });
      }
    } catch (error) {
      console.log("error: ", error);
      setHistory({ ...history, fetching: false });
    }
  };

  const openDrawForm = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 500,
      name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
      title: "Draw Spare Part",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
        });
      },
      onOk: FormInstance.submit,
      okText: "Submit",
      children: (
        <DrawWorkOrderSparePart
          getHistory={getHistory}
          workOrder={props.task as any}
          FormInstance={FormInstance}
          callback={() => props?.getPmExternalDetails(props.task?.id)}
        />
      ),
    });
  };

  return (
    <Spin spinning={props.loader}>
      <div className="work-order-status-container">
        <div className="status-asset-section">
          <Row wrap={false}>
            <Col flex="200px">
              <div className="alectify-pm-basic-info-image-card">
                <Spin spinning={isLoadingImage}>
                  <div
                    className={`alectify-pm-basic-info-image ${
                      props.task?.imageUrl ? "cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      props.task?.imageUrl &&
                      openImagePreviewModal(props.task?.imageUrl)
                    }
                    style={{
                      backgroundImage: `url(${
                        props.task?.imageUrl || NO_IMAGE
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <Upload
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      const { fileList } = info;
                      handleFileUpload(fileList[0].originFileObj);
                    }}
                    fileList={[]}
                    beforeUpload={() => false}
                  >
                    <div className="edit-profile-text">
                      {props.task?.imageUrl ? "Change Image" : "Add Image"}
                    </div>
                  </Upload>
                </Spin>
              </div>
            </Col>
            <Col flex="auto">
              <div className="status-work-order-detail-section">
                {!props.loader ? (
                  <div>
                    <span className="pm-detail-title-text">
                      {props.task?.workTitle}
                    </span>

                    <Space className="space-container">
                      <span className="critical-chip">
                        {
                          PM_DETAIL_PRIORITY_COMPONENTS[
                            props.task?.priority as TaskPriorityEnum
                          ]
                        }
                      </span>

                      <span className="alectify-pm-info-detail-value">
                        <AlectifyChip
                          text={`Due Date: ${
                            displayDateTime(props.task?.dueDate) || "-"
                          }`}
                          backgroundColor={"#ffebeb"}
                          borderColor={"#f94c4c"}
                          textColor={"#f94c4c"}
                        />
                      </span>

                      <span className="alectify-pm-info-detail-value">
                        <AlectifyChip
                          text={enumToTile(
                            props.task?.pmType === PM_TYPES.PM_EXTERNAL
                              ? props.task?.taskCategory
                              : props.task?.pmType || "",
                          )}
                          backgroundColor={"#e8f1fd"}
                          borderColor={"#0954f1"}
                          textColor={"#0954f1"}
                        />
                      </span>

                      <div className="site-asset-container">
                        {props.task?.asset?.name || props.task?.area?.name ? (
                          <>
                            <span className="key-text">
                              {props.task?.area?.name
                                ? "Parent asset:"
                                : "Sub asset:"}
                            </span>
                            <div
                              className="alectify-pm-info-detail-key-site"
                              onClick={() => openAssetDetail(props.task)}
                            >
                              {props.task?.asset?.name ? (
                                <span className="text-link">
                                  {props.task.asset.name}
                                </span>
                              ) : (
                                <span className="text-link">
                                  {props.task?.area?.name}
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          <span>{AlectifyText.GENERIC_WORK_ORDER}</span>
                        )}
                      </div>
                    </Space>

                    <span className="sub-info">
                      {renderWorkOrderRepeats() != null ? (
                        <>
                          <strong className="recurring-text">Recurring:</strong>{" "}
                          {renderWorkOrderRepeats()}
                        </>
                      ) : (
                        <></>
                      )}
                    </span>

                    <span className="work_id-container">
                      {AlectifyText.WORK_ID}: {props.task?.workId}
                    </span>

                    <div className="description-body">
                      <AlectifyDescription
                        detail={props.task?.detail || ""}
                        height={"80px"}
                      />
                    </div>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="border-title d-flex justify-content-space-between align-items-center">
          <Typography.Title level={5} className="mt-5">
            {AlectifyText.WORK_ORDER_STATUS}
          </Typography.Title>

          <span>{props.actionButton}</span>
        </div>

        <Row gutter={[6, 6]} align="middle" className="pt-10 pb-10">
          <Col xs={24} sm={12} md={7}>
            <StatusCard
              text="Assignees"
              helperText={
                getPMEActionRequired(props.task as any) ===
                PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                  ? "(Action Required)"
                  : ""
              }
              users={props.task?.assignees?.map(({ user }) => user) || []}
            />
          </Col>
          <Col xs={24} sm={12} md={7}>
            <StatusCard
              text="Approvers"
              helperText={
                getPMEActionRequired(props.task as any) ===
                PME_ACTION_REQUIRED_ENUM.APPROVERS
                  ? "(Action Required)"
                  : ""
              }
              users={props.task?.approvers?.map(({ user }) => user) || []}
            />
          </Col>
          <Col xs={24} sm={12} md={7}>
            <StatusCard
              text="Team members"
              users={
                (!isEmpty(props.task?.team) &&
                  props.task?.team.projectTeamMembers.map((d) => d.user)) ||
                (!isEmpty(props.task?.teamMembers) &&
                  props.task?.teamMembers?.map((d: any) => d.user)) ||
                []
              }
            />
          </Col>
        </Row>

        <div className="pm-detail-info-steps mb-10">
          <Steps current={3} status={"process"} items={getStepsItems()} />
        </div>
        {/* <div className="p-5 border-title">
          <Typography.Title level={5} className="mt-5">
            {AlectifyText.MORE_INFORMATION}
          </Typography.Title>
        </div> */}

        <Divider />

        <Collapse bordered={false}>
          <Panel
            header={
              <div className="d-flex justify-content-space-between align-items-center">
                {AlectifyText.SPARE_PARTS} (
                {props.task?.sparePartsUsed?.count || "0"})
                <AlectifyButton
                  text="Draw Spare Part"
                  type="primary"
                  onClick={() => openDrawForm()}
                />
              </div>
            }
            key="1"
          >
            {props.task && (
              <WorkOrderSpareParts
                workOrderDetailPage
                workOrderGetHistory={getHistory}
                workOrderSParePartsHistory={history}
                record={props.task as any}
                showDrawSparePartsButton={false}
                callback={() => props?.getPmExternalDetails(props.task?.id)}
              />
            )}
          </Panel>
        </Collapse>
        {!isEmpty(props.procedure) && (
          <Collapse bordered={false} className="mt-10">
            <Panel
              header={
                <>
                  {AlectifyText.PROCEDURE_STEPS} (
                  {countCheckedSteps(
                    props.task?.procedure?.procedureSteps || [],
                  )}
                  )
                </>
              }
              key="2"
            >
              {isEmpty(props?.procedure) ? (
                <AlectifyEmpty description="No data" />
              ) : (
                <ProcedureDetail
                  isDetailPage
                  procedure={props.procedure}
                  taskId={props.taskId}
                  subProjectId={props.subProjectId}
                  referenceId={props?.referenceId}
                  isDisabled={props.isDisabled}
                  getPmExternalDetails={props.getPmExternalDetails}
                />
              )}
            </Panel>
          </Collapse>
        )}
      </div>
    </Spin>
  );
};

export default WorkOrderStatus;
