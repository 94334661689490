import { get } from "lodash";
import { memo } from "react";
import { Divider, Popover, Space } from "antd";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { CalendarEventStatusEnum, CalendarEventTypesEnum } from "enums";
import { CheckMarkSingleIcon } from "components/icons";
import TasksIcon from "components/icons/TasksIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
import PmInternalIcon from "components/icons/PmInternalIcon";
import AlectifyText from "static/texts.json";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import { SkippedTag } from "components/shared/tags";
import DelayedTag from "components/shared/tags/DelayedTag";
import { displayDateTime } from "utils/helpers";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import ClosedTag from "components/shared/tags/ClosedTag";
import { CategoryLabels } from "constants/dashboard.constants";
import "./Calendar.scss";

export const EVENT_ICONS = {
  [CalendarEventTypesEnum.TASKS]: <TasksIcon />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIcon />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIcon />,
};

export const EVENT_STATUS_CHIPS = {
  [CalendarEventStatusEnum.DELAYED]: <DelayedTag />,
  // [CalendarEventStatusEnum.PENDING]: <ScheduledTag />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <ClosedTag />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <ClosedTag text={"Closed"} color={"#d92323"} fill={"#f94c4c"} />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <SkippedTag />,
};

export const EVENT_CLASSES: any = {
  [CalendarEventStatusEnum.DELAYED]: "delayed",
  [CalendarEventStatusEnum.PENDING]: "pending",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "completed",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "completed",
  [CalendarEventStatusEnum.SKIPPED]: "skipped",
};

export const EVENT_Text: any = {
  [CalendarEventStatusEnum.DELAYED]: "Over Due",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "Completed(Due Date Passed)",
};

const CalendarEvent: React.FC<ICalendarEvent> = (props: ICalendarEvent) => {
  const masterProject = get(props, "master_project[0]");
  const navigate = useNavigate();

  const goToPmDetailPage = (props: any) => {
    const masterProjectId = get(props, "master_project[0].id");
    const subProjectId = get(props, "sub_project[0].id");
    const pmId = props.id;
    const pmType = props.type;

    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${pmType}`;
    navigate(url);
  };

  const popoverContent = (
    <div className="popover-container-calendar">
      <Space direction="vertical">
        <div className="heading-sub-container">
          <span className="heading">{`${props?.title}`}</span>
          <>
            {/* {props.asset_package[0]?.name || props.asset[0]?.name ? (
              <div className="sub-heading">
                <strong>
                  {props.taskCategory &&
                  CategoryLabels[
                    props.taskCategory as keyof typeof CategoryLabels
                  ]
                    ? CategoryLabels[
                        props.taskCategory as keyof typeof CategoryLabels
                      ]
                    : "Task"}
                </strong>
                {" on "}
                <strong>
                  {get(props, "asset[0].name")
                    ? AlectifyText.SUB_ASSET_NAME
                    : AlectifyText.PARENT_ASSET_NAME}
                </strong>
                {`: ${
                  get(props, "asset[0].name") ||
                  get(props, "asset_package[0].name") ||
                  "-"
                }`}
              </div>
            ) : (
              <div className="sub-heading">
                <strong>
                  {props.taskCategory &&
                  CategoryLabels[
                    props.taskCategory as keyof typeof CategoryLabels
                  ]
                    ? CategoryLabels[
                        props.taskCategory as keyof typeof CategoryLabels
                      ]
                    : "Task"}
                </strong>
                {" on "}
                <strong>Generic</strong>
              </div>
            )} */}
            <Divider className="mb-5 mt-5" />
            <div className="sub-heading">
              Work Id: <strong>{props?.work_id}</strong>
            </div>
            <div className="sub-heading">
              Type:{" "}
              <strong>
                {props.taskCategory &&
                CategoryLabels[
                  props.taskCategory as keyof typeof CategoryLabels
                ]
                  ? CategoryLabels[
                      props.taskCategory as keyof typeof CategoryLabels
                    ]
                  : "Task"}
              </strong>
            </div>
            <div className="sub-heading">
              Asset:{" "}
              {`${
                get(props, "asset[0].name") ||
                get(props, "asset_package[0].name") ||
                "None"
              }`}{" "}
              (
              <strong>
                {get(props, "asset[0].name")
                  ? AlectifyText.SUB_ASSET_NAME
                  : get(props, "asset_package[0].name")
                  ? AlectifyText.PARENT_ASSET_NAME
                  : AlectifyText.GENERIC}
                )
              </strong>
            </div>
          </>
        </div>

        <Space className="chips-main">
          <span className="event-chips">
            <AlectifyChip
              text={`Due Date: ${displayDateTime(props?.dueDate) || "-"}`}
              backgroundColor={"#ffebeb"}
              borderColor={"#f94c4c"}
              textColor={"#f94c4c"}
            />
          </span>

          <span className="width-100">
            {
              EVENT_STATUS_CHIPS[
                props.event_status as keyof typeof EVENT_STATUS_CHIPS
              ]
            }
          </span>
        </Space>
      </Space>
    </div>
  );

  return (
    <div className="event-container">
      <Popover
        content={popoverContent}
        trigger="hover"
        placement="topRight"
        overlayClassName={"events-popover"}
      >
        <div
          className={`alectify-calendar-event ${
            EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
          }`}
          style={{ backgroundColor: masterProject?.color || "transparent" }}
          onClick={() => {
            goToPmDetailPage(props);
          }}
        >
          <div
            className={`event-content ${
              props?.event_status === CalendarEventStatusEnum.DELAYED ||
              props?.event_status ===
                CalendarEventStatusEnum.DELAYED_COMPLETED ||
              props?.event_status === CalendarEventStatusEnum.SKIPPED
                ? "event-left-red-borders"
                : "event-left-normal-borders"
            }`}
          >
            {props?.event_status ===
              CalendarEventStatusEnum.DELAYED_COMPLETED ||
            props?.event_status ===
              CalendarEventStatusEnum.ON_TIME_COMPLETED ? (
              <div className="calendar-check-mark">
                <CheckMarkSingleIcon fill={"#fff"} />
              </div>
            ) : (
              <></>
            )}
            <div className="event-title">
              <span>{props?.title || "NA"}</span>
            </div>

            <div className="event-name text-ellipsis">
              <span>
                {get(props, "asset[0].name") ||
                  get(props, "asset_package[0].name") ||
                  "-"}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default memo(CalendarEvent);
