import React, { ReactNode } from "react";
import { isEmpty } from "lodash";
import { ITask } from "redux/components/tasks";
import { Avatar, Dropdown, Space, Tooltip, message } from "antd";
import { displayDateTime, getFullUserName } from "utils/helpers";
import {
  TaskStatusEnum,
  TaskPriorityEnum,
  PM_STATUS,
  PME_ACTION_REQUIRED_ENUM,
  MaintenanceCategoriesEnum,
} from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { EllipsisOutlined, CloseOutlined } from "@ant-design/icons";
import { actionItems } from "../TaskTable.rules";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import {
  ApprovedTag,
  ApproverTag,
  AssigneeTag,
  CompletedTag,
  ScheduledTag,
  WaitingForApprovalTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

import "assets/scss/global.scss";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useNavigate } from "react-router-dom";
import ClosedTag from "components/shared/tags/ClosedTag";
import { CalendarDateIcon, UserIcon, WarningIcon } from "components/icons";
import { ITasksTableProps } from "../TasksTable.interface";
import { IProcedure } from "services/procedures/procedures.interface";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import { IPmAssignees } from "redux/interfaces";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import ProcedureDetail from "components/shared/procedure-detail";
import { undoTaskStatus } from "services/tasks/tasks.service";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import ViewImage from "components/shared/view-image/ViewImage";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { ROUTES } from "routes/Routes.constants";
import { actions as NotificationActions } from "redux/components/notifications";
import "../TasksTable.scss";

export interface ITaskTableColumnHookProps extends ITasksTableProps {
  onEdit: (_: any, editing: boolean, editType: string, record: ITask) => void;
  filter: string[];
  optionsPersisted?: IPagination | null;
  fetchTasks: (options?: IPagination) => void;
  updateStatus: (pmId: IPmExternal | ITask, status: string) => void;
  openSubmitForReviewModal: (task: ITask) => void;
  removeTask: (id: string) => void;
  openSpareParts?: (task: IPmExternal | ITask) => void;
}

export const TASK_STATUS_COMPONENTS = {
  [TaskStatusEnum.PENDING]: <ScheduledTag />,
  [TaskStatusEnum.APPROVED]: <ApprovedTag />,
  [TaskStatusEnum.SCHEDULED]: <ScheduledTag />,
  [TaskStatusEnum.COMPLETED]: <ClosedTag />,
  [TaskStatusEnum.REVIEW_DECLINED]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: <WaitingForApprovalTag />,
};
export const taskStatusStepIcon = (
  currentStatus: string,
  statusToMove: string,
): ReactNode => {
  if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return AlectifyText.IN_PROGRESS;
  } else if (
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.COMPLETED
  ) {
    return AlectifyText.REVIEW_COMPLETE;
  }
  return <></>;
};
export const TASK_PROIRITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: <CompletedTag text={AlectifyText.NORMAL} />,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const PM_DETAIL_PRIORITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: null,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag />;
    default:
      return <></>;
  }
};

export const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
  const isAllChecked =
    procedure.procedureStepCheckedTotalCount ===
    procedure.procedureStepTotalCount;
  return isAllChecked ? (
    <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
  ) : (
    <StampCheckIcon />
  );
};

const useSimpleColumns = (props: ITaskTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTaskType = props.type === PM_TYPES.TASK;

  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const goToPmDetail = (task: ITask) => {
    const pmId = task.id;
    const masterProjectId = task?.project?.id;
    const subProjectId = task?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
    dispatch(NotificationActions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER],
    });
  };

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },

    onClick: () => {
      goToPmDetail(record);
    },
  });
  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.CREATED_AT,
          key: "createdAt",
          dataIndex: "createdAt",
          visible: true,
          sorter: true,
          sortingColumn: "createdAt",
          ellipsis: true,
          width: "13%",
          onCell,
          render: (target: any, record: ITask) => (
            <div className="d-flex justify-content-space-between">
              <Space direction="vertical" size={15}>
                <div className="creator-container">
                  <CalendarDateIcon />
                  <span className="creator-name-date">
                    {`${displayDateTime(
                      record?.createdAt || record?.createdAt,
                      true,
                      false,
                    )}`}
                  </span>
                </div>
              </Space>
            </div>
          ),
        },
        {
          title: AlectifyText.TITLE,
          dataIndex: "workTitle",
          width: "29%",
          visible: true,
          ellipsis: true,
          searchable: true,
          onCell,
          render: (value: string, task: ITask) => {
            return (
              <div className="title-container-wo">
                <Tooltip title={value}>
                  <span className="ml-5 mt-2 text-ellipsis text-link-title">
                    {value}
                  </span>
                </Tooltip>
                <Tooltip title={TaskPriorityEnum.CRITICAL}>
                  {task?.priority === TaskPriorityEnum.CRITICAL ? (
                    <WarningIcon height={12} width={17} />
                  ) : null}
                </Tooltip>
              </div>
            );
          },
        },
        {
          title: "Maintenance Type",
          key: "taskCategory",
          dataIndex: "taskCategory",
          visible: !isTaskType,
          ellipsis: true,
          width: "12%",
          onCell,
          render: (target: any, record: ITask) => (
            <>
              {`${
                record?.taskCategory ===
                MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                  ? AlectifyText.CORRECTIVE_MAINTENANCE
                  : AlectifyText.PREVENTIVE_MAINTENANCE
              }`}
            </>
          ),
        },

        {
          title: "Due date",
          visible: true,
          key: "dueDate",
          dataIndex: "dueDate",
          width: "10%",
          sorter: true,
          onCell,
          render: (dueDate: Date, record: ITask) => {
            return <>{displayDateTime(dueDate)}</>;
          },
        },
        {
          title: AlectifyText.STATUS,
          dataIndex: "dueDate",
          key: "dueDate",
          width: "15%",
          visible: true,
          onCell,
          render: (value: Date, task: ITask) => {
            return <>{PM_STATUS_COMPONENTS[task.status]}</>;
          },
        },

        {
          title: AlectifyText.ACTION_REQUIRED_BY,
          dataIndex: "assignees",
          key: "assignees",
          width: "10%",
          filters: projectTeamMembers?.data.map((user) => ({
            text: getFullUserName(user),
            value: user.id,
          })),
          visible:
            props.filter.includes(PM_STATUS.PENDING) ||
            props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW),
          render: (assignees: IPmAssignees[], record: IPmExternal) => {
            const actionRequired = getPMEActionRequired(record);
            if (!isEmpty(assignees) || !isEmpty(record.approvers)) {
              return (
                <Space direction="vertical" size={5}>
                  <AvatarGroup
                    maxCount={2}
                    showImage
                    users={
                      actionRequired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                        ? assignees.map(({ user }) => user)
                        : record.approvers?.map(({ user }) => user)
                    }
                  />
                </Space>
              );
            }
            return <></>;
          },
        },
        {
          title: "Created by",
          visible: true,
          key: "created",
          dataIndex: "created",
          width: "6%",
          render: (_: any, record: ITask) => {
            return (
              <>
                {isEmpty(record?.createdBy?.image_url) ? (
                  <UserIcon />
                ) : (
                  <Tooltip
                    title={`${getFullUserName(record?.createdBy as any)}`}
                  >
                    <Avatar
                      src={record?.createdBy?.image_url}
                      className="cursor-pointer"
                      onClick={() =>
                        ModalServiceInstance.open(AlectifyModal, {
                          name: "alectify-task-image-viewer",
                          title: "Preview",
                          footer: null,
                          onCancel: () => {
                            ModalServiceInstance.close(AlectifyModal, {
                              name: "alectify-task-image-viewer",
                            });
                          },
                          children: (
                            <ViewImage
                              imageUrl={
                                record?.createdBy?.image_url || NO_IMAGE_AVATAR
                              }
                              isDownloadable={
                                !isEmpty(record?.createdBy?.image_url)
                              }
                            />
                          ),
                        })
                      }
                    />
                  </Tooltip>
                )}
              </>
            );
          },
        },
        {
          width: "5%",
          title: AlectifyText.ACTIONS,
          align: "center",
          fixed: "right",
          dataIndex: "",
          visible: true,
          render: (_: any, task: ITask) => {
            const openAttachmentsDrawer = () => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 860,
                title: task?.asset?.name || task?.area?.name,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () => {
                  DrawerServiceInstance.close(AlectifyDrawer);
                  props.fetchTasks(props.optionsPersisted as any);
                },
                children: <PreventiveDocumentsAttachment details={task} />,
              });
            };
            const openProcedureCheckListDrawer = () => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 680,
                title: "Procedure",
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () => {
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                  });
                  props.fetchTasks(props.optionsPersisted as any);
                },
                showFooter: true,
                destroyOnClose: true,
                readOnly: true,
                cancelText: AlectifyText.CLOSE,
                children: (
                  <ProcedureDetail
                    procedure={task.procedure as any}
                    taskId={task.id}
                    isDisabled={
                      task.status === PM_STATUS.COMPLETED ||
                      task.status === PM_STATUS.SKIPPED
                    }
                  />
                ),
              });
            };

            const handleUndoConfirm = async () => {
              try {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.UNDO_WARNING_MODAL,
                });
                const resp = await undoTaskStatus(task.id);
                if (resp?.status) {
                  message.success(
                    `${task?.workTitle} has changed the state from ${task?.status} to ${resp?.data?.status}`,
                  );
                  props.fetchTasks(props.optionsPersisted as any);
                }
              } catch (error) {
                message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
              }
            };
            const openWarningmodal = () => {
              ModalServiceInstance.open(AlectifyModal, {
                name: MODAL_NAMES.UNDO_WARNING_MODAL,
                title: AlectifyText.CONFIRMATION,
                footer: null,
                onCancel: () => {
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  });
                },
                children: (
                  <ConfirmationModal
                    icon={WarningIcon}
                    message={MESSAGES.PM_EXTERNAL.REOPEN_WARNING}
                    note={MESSAGES.PM_EXTERNAL.REOPEN_NOTE}
                    onConfirm={handleUndoConfirm}
                    okText="Re open"
                    cancelText="Cancel"
                    onCancel={() =>
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.UNDO_WARNING_MODAL,
                      })
                    }
                  />
                ),
              });
            };

            const dropdownItems = actionItems({
              currentUser,
              showMyWorkOrders,
              task,
              projectId: props.masterProjectId || "",
              subProjectId: props.subProjectId || "",
              navigate,
              myItemsColumns: props.myItemsColumns,
              onEdit: props.onEdit,
              updateStatus: props.updateStatus,
              openSubmitForReviewModal: props.openSubmitForReviewModal,
              onUpload: openAttachmentsDrawer,
              openProcedureCheckListDrawer,
              removeTask: props.removeTask,
              openSpareParts: props.openSpareParts,
              openWarningmodal: openWarningmodal,
              taskStatusStepIcon: taskStatusStepIcon,
              showDetailPageLink: true,
              goToPmDetail,
            });

            const renderDropdown: any =
              dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

            return (
              <>
                <Dropdown
                  menu={{
                    items: renderDropdown,
                  }}
                  trigger={["click"]}
                >
                  <EllipsisOutlined
                    rotate={90}
                    className="alectify-action-icon"
                  />
                </Dropdown>
              </>
            );
          },
        },
      ].filter((column) => column.visible),
    [isTaskType, props.myItemsColumns],
  );
};
export default useSimpleColumns;
